<template>
  <div class="contact"><WolfVisionComponent /></div>
</template>
      
      <script>
import WolfVisionComponent from "@/components/WolfVisionComponent.vue";

export default {
  name: "WolfVisionView",
  components: {
    WolfVisionComponent,
  },
};
</script>
  
      <style>
</style>