<template>
  <div class="languageSwitch">
    <LanguageSwitch />
  </div>
</template>

<script>
// @ is an alias to /src
import LanguageSwitch from "@/components/LanguageSwitchComponent.vue";

export default {
  name: "HomeView",
  components: {
    LanguageSwitch,
  },
};
</script>