<template>
  <div class="home">
    <SwitchLang @changed="onChange" :initLang="initLang" :options="options" />
  </div>
</template>

<script>
import SwitchLang from "vue-switch-lang";

export default {
  components: {
    SwitchLang,
  },
  methods: {
    onChange(lang) {
      console.log(lang);
    },
  },
  data() {
    return {
      initLang: {
        title: "en",
        flag: "gb",
      },
      options: [
        {
          title: "en",
          flag: "gb",
        },
        {
          title: "de",
          flag: "de",
        },
        {
          title: "fa",
          flag: "ir",
        },
      ],
    };
  },
};
</script>

<style >
.multiselect__option--selected {
  background-color: red;
}

.multiselect__option--highlight {
  background-color: green;
  background: green;
}
</style>