<template>
  <div>
    <Portfolio />
  </div>
</template>
    
    <script>
import Portfolio from "@/components/PortfolioComponent.vue";
export default {
  name: "PortfolioView",
  components: {
    Portfolio,
  },
};
</script>