import { defineStore } from 'pinia'

export const useWindowStore = defineStore({
    id: "window",
    state: () => ({
        width: 0,
        height: 0
    }),
    getters: {
        isPhoneLayout: (state) => state.width < 500
    },
    actions: {
        init() {
            this.width = window.innerWidth
            this.height = window.innerHeight

            window.addEventListener("resize", () => {
                if (window.innerWidth != this.height) {
                    this.width = window.innerWidth
                }
                if (window.innerHeight != this.height) {
                    this.height = window.innerHeight
                }
            })
        }
    }
})
