<template>
  <div class="contact"><ApenzellComponent /></div>
</template>
    
    <script>
import ApenzellComponent from "@/components/ApenzellComponent.vue";

export default {
  name: "ApenzellView",
  components: {
    ApenzellComponent,
  },
};
</script>
    <style>
</style>