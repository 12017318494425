<template>
  <div>
    <header>
      <!-- {{ windowStore.isPhoneLayout }} -->
      <div class="container">
        <div class="navbar-header">
          <div id="logo">
            <a v-if="windowStore.width > 700" class="l" @click="$router.push('/')"><img class="img"
                src="@/assets/logo.png" alt="" /></a>
          </div>
        </div>
        <nav>
          <ul :class="{ headerItems: windowStore.width < 500 }">
            <a class="mt" @click="$router.push('/services')">Services</a>

            <a class="mt" @click="$router.push('/portfolio')">Portfolio</a>

            <a class="contact" @click="$router.push('/contact')">Contact</a>
          </ul>
        </nav>
      </div>
    </header>
    <!-- <nav>
      <div class="left-container">
        <Dropdown class="dropdown" title="Menu" :items="services" />
      </div>
      <div class="mid-container">
        <div class="menu-item">
          <a @click="$router.push('/')"><img src="" alt="LA" /> </a>
        </div>
      </div>
      <div class="right-container">
        <div class="menu-item">
          <a @click="$router.push('/contact')">Contact Us</a>
        </div>
      </div>
    </nav> -->
  </div>
</template>
<script setup>
//import router from "@/router";
import { useWindowStore } from "@/store/window.store";
const windowStore = useWindowStore();
// import Dropdown from "./DropdownComponent";

// services: [
//   {
//     title: "Web",
//     link: "/#",
//   },
//   {
//     title: "Services",
//     link: "/services",
//   },
//   {
//     title: "Portfolio",
//     link: "/#",
//   },
// ]

// function openContactView() {
//   router.push("/contact");
// }
</script>

<style scoped>
.headerItems {
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 500px;
}

a {
  font-size: larger;
  padding: 10px;
  cursor: pointer;
}

.contact {
  color: rgb(0, 0, 0);
  text-decoration: none;
  border: 2px solid #000000;
  border-radius: 16px;
}

.mt {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-left: 50px;
  padding-right: 50px;

  background: linear-gradient(335deg,
      #abc4ff 0%,
      #abc4ff 14%,
      #b6ccfe 28%,
      #c1d3fe 42%,
      #ccdbfd 56%,
      #d7e3fc 70%,
      #e2eafc 84%,
      #edf2fb 100%);
  -webkit-box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.45);
}

.img {
  width: 25%;
  height: 25%;
}

.left-container {}

.mid-container {
  align-items: center;
  padding: 10px;
}

.right-container {}

nav {
  margin-left: auto;
  font-size: larger;
}

nav .menu-item {
  background-color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  position: relative;
  text-align: center;
  border-bottom: 3px solid transparent;
  display: flex;
  transition: 0.4s;
  border-radius: 16px 16px 16px 16px;
  border-color: rgb(0, 0, 0);
}

nav .menu-item.active,
nav .menu-item:hover {
  border-block-color: rgb(3, 233, 229);
  border-radius: 16px 16px 16px 16px;
}

/* nav .menu-item a {
  color: inherit;
  text-decoration: none;
  font-size: 35px;
} */
/* .rl {
  color: white;
}
.dropDownMenu a {
  color: white;
}
nav {
  color: white;
  text-align: center;
  height: 100px;
  background-color: #1e201e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropDownMenu {
  -ms-flex-align: center;
  width: 30%;
  font-size: 250%;
  color: rgb(121, 121, 121);
}
.dropDownMenu.active,
.dropDownMenu a:hover {
  color: darkgrey;
}

.logo {
  text-align: center;
  width: 60%;
  font-size: 250%;
}
.contactbtn {
  text-align: center;
  width: 30%;
}
.contactButton {
  position: static;
  color: black;
  background-color: white;
  font-size: large;
} */
</style>