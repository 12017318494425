<template>
  <div>
    <div>
      <h1 class="title">𝐏𝐫𝐨𝐣𝐞𝐜𝐭𝐬</h1>
    </div>
    <div>
      <h3 class="title-text">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      </h3>
    </div>
    <div class="grid-container">
      <div v-for="element in data" :key="element.id" class="cont">
        <a @click="$router.push(element.route)"><img :src="element.image" alt=""
            @mouseover="hovers[element.id - 1].value = true" @mouseout="hovers[element.id - 1].value = false" />
        </a>
        <div v-if="hovers[element.id - 1].value" class="text-block" @click="$router.push(element.route)">
          <h4>{{ element.title }}</h4>
          <p>{{ element.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";

const data = [
  {
    id: 1,
    image:
      "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY",
    title: "test1",
    text: "What a beautiful sunrise",
    route: "/contact",
  },
  {
    id: 2,
    image:
      "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY",
    title: "test2",
    text: "What a beautiful sunrise2",
    route: "/portfolio",
  },
  {
    id: 3,
    image:
      "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY",
    title: "test3",
    text: "What a beautiful sunrise3",
    route: "/",
  },
  {
    id: 4,
    image:
      "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY",
    title: "test4",
    text: "What a beautiful sunrise4",
    route: "",
  },
  {
    id: 5,
    image:
      "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY",
    title: "test5",
    text: "What a beautiful sunrise5",
    route: "",
  },
  {
    id: 6,
    image:
      "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY",
    title: "test6",
    text: "What a beautiful sunrise6",
    route: "",
  },
];

const hovers = [
  ref(false),
  ref(false),
  ref(false),
  ref(false),
  ref(false),
  ref(false),
];

// for (var hover of hovers) {
//   watch(hover, () => {
//     console.log(hover.value);
//   });
// }
</script>
  
<style scoped>
.text-block {
  position: absolute;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  opacity: 0.5;
}

.cont {
  position: relative;
  text-align: center;
  color: white;
}

.img-text {
  font-size: 30px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  cursor: pointer;
  z-index: -10;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  padding: 5px;
}

img:hover {
  background-color: white;
  opacity: 0.5;
}

.grid-container {
  justify-content: center;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  padding: 15px;
}

.title {
  margin-top: 75px;
  text-align: center;
}

.title-text {
  min-width: fit-content;
  float: inline-start;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
</style>
  