<template>
  <div>
    <Services />
  </div>
</template>
  
  <script>
// @ is an alias to /src
import Services from "@/components/ServicesComponent.vue";
export default {
  name: "HomeView",
  components: {
    Services,
  },
};
</script>