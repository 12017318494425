<template>
  <div>
    <div class="wrapper">
      <h1>Our Services</h1>
      <p>Lorem ipsum dolor sit amet, consetetur</p>
      <div class="content-box">
        <div @click="mobileText" class="card">
          <h2>Mobile Development</h2>
          <p>Lorem ipsum dolor sit amet, consetetur</p>
        </div>

        <div @click="webText" class="card">
          <h2>Web Development</h2>
          <p>Lorem ipsum dolor sit amet, consetetur</p>
        </div>

        <div @click="otherText" class="card">
          <h2>Somthing else</h2>
          <p>Lorem ipsum dolor sit amet, consetetur</p>
        </div>
      </div>
      <div class="text-box">
        <h2>{{ title }}</h2>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>



<script setup>
import { ref } from "vue";

const clickedItem = ref(0);
const title = ref("Title");
const text = ref("Lorem ipsum dolor sit amet, consetetur");

//function onclick() {
//  return (clickedItem = 0);
//}

const wahr = true;

function mobileText() {
  clickedItem.value = 1;
  title.value = "Mobile Development";
  text.value = "MOBILETEXT";
}
function webText() {
  if (wahr) {
    clickedItem.value = 2;
  } else {
    clickedItem.value = 0;
  }
  title.value = "Web Development";
  text.value = "WEBTEXT";
}
function otherText() {
  if (wahr) {
    clickedItem.value = 3;
  } else {
    clickedItem.value = 0;
  }
  title.value = "Somthing else";
  text.value = "testset";
}
</script>


<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  font-size: 3em;
  margin: 25px 0;
  text-align: center;
  font-weight: 500;
}

.content-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1000px;
  margin-top: 30px;
}

.card {
  min-height: 220px;
  width: 320px;

  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  margin: 10px 4px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card h2 {
  margin-bottom: 12px;
  font-weight: 400;
  text-align: center;
}

.card p {
  color: #6c757d;
  text-align: center;
}

.card:hover p {
  color: #fff;
}

.card:hover h2 {
  font-weight: 600;
  color: #f2f2f2;
}

.card:nth-child(1):hover {
  background: linear-gradient(
      45deg,
      rgba(255, 28, 8, 0.8) 0%,
      rgba(255, 0, 82, 0.8) 100%
    ),
    url("@/assets/home_wp.jpg");
  background-size: cover;
  cursor: pointer;
}

.card:nth-child(2):hover {
  background: linear-gradient(
      45deg,
      rgba(255, 28, 8, 0.8) 0%,
      rgba(255, 0, 82, 0.8) 100%
    ),
    url("@/assets/home_wp.jpg");
  background-size: cover;
  cursor: pointer;
}

.card:nth-child(3):hover {
  background: linear-gradient(
      45deg,
      rgba(255, 28, 8, 0.8) 0%,
      rgba(255, 0, 82, 0.8) 100%
    ),
    url("@/assets/home_wp.jpg");
  background-size: cover;
  cursor: pointer;
}

.text-box {
  min-height: 220px;
  width: 990px;

  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  margin: 10px 4px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.text-box h2 {
  margin-bottom: 12px;
  font-weight: 400;
  text-align: center;
}
.text-box p {
  color: #6c757d;
  text-align: center;
}

@media (max-width: 991px) {
  .wrapper {
    padding: 25px;
  }

  .wrapper h1 {
    font-size: 2.5em;
    font-weight: 600;
  }

  .content-box {
    flex-direction: column;
    width: 100%;
  }

  .card {
    min-width: 300px;
    margin: 10px auto;
  }
}
</style>









<!-- <template>
  <div>
    <h1>Services</h1>
    <div class="services-text-container">
      <div class="services-title-container">
        <div
          @click="mobileText"
          class="item"
          :class="{ otherColor: clickedItem === 1 }"
        >
          <h3 class="services-titel">Mobile Development</h3>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>

         
        </div>
        <div
          @click="webText"
          class="item"
          :class="{ otherColor: clickedItem === 2 }"
        >
          <div class="services-titel"><h3>Web Development</h3></div>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
        </div>
        <div
          @click="otherText"
          class="item"
          :class="{ otherColor: clickedItem === 3 }"
        >
          <div class="services-titel"><h3>Somthing else</h3></div>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
          <p class="tesettt">asdfasfdasfadfsadf</p>
        </div>
      </div>
    </div>

    <div class="slider">
      <img src="@/assets/logo.png" alt="error" />
      <p class="info-text">{{ text }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const clickedItem = ref(0);
const text = ref(
  "loreadfjkladjflakdjflkajdflkajsfdlköajflökajsflökasjflköasjfkl"
);

//function onclick() {
//  return (clickedItem = 0);
//}

const wahr = true;

function mobileText() {
  clickedItem.value = 1;
  text.value = "MOBILETEXT";
}
function webText() {
  if (wahr) {
    clickedItem.value = 2;
  } else {
    clickedItem.value = 0;
  }
  text.value = "WEBTEXT";
}
function otherText() {
  if (wahr) {
    clickedItem.value = 3;
  } else {
    clickedItem.value = 0;
  }
  text.value = "testset";
}
</script>

<style scoped>
.slider {
  display: flex;

  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-shadow: 5px 5px 15px 0px #000000;
  box-shadow: 5px 5px 15px 0px #000000;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto;
  padding: 20px;
  border: 2px solid black;
  border-radius: 1px;
  border-radius: 20px;
}
.otherColor {
  background-color: rgb(255, 255, 255);
}
h1 {
  padding: 50px;
  text-align: center;
}
.services-text-container {
  align-items: center;
  justify-content: center;
}
.services-slider {
  width: 50%;
  text-align: end;
}
.services-title-container {
  display: flex;
}
.item {
  -webkit-box-shadow: 5px 5px 15px 0px #000000;
  box-shadow: 5px 5px 15px 0px #000000;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  padding: 20px;
  border: 2px solid black;
  border-radius: 1px;
  border-radius: 20px;
}
.item:hover {
  background-color: rgb(255, 255, 255);
}
.services-titel {
  text-align: center;
  padding: 10px;
}
.info-text {
  padding: 20px;
  text-align: center;
}
p {
  text-align: center;
}
li {
  color: brown;
}
</style> -->