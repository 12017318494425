<template>
  <div class="carousel-action">
    <carousel-component @next="next" @prev="prev">
      <carousel-slide-component
        v-for="(slide, index) in slides"
        :key="slide"
        :index="index"
        :visibleSlide="visibleSlide"
        :direction="direction"
      >
        <img :src="slide" class="slide-image" />
      </carousel-slide-component>
    </carousel-component>
  </div>
</template>


<script setup>
import CarouselSlideComponent from "./CarouselSlideComponent.vue";
import CarouselComponent from "./CarouselComponent.vue";
import { ref } from "vue";

const slides = [
  "https://picsum.photos/id/237/600/350",
  "https://picsum.photos/id/236/600/350",
  "https://picsum.photos/id/234/600/350",
  "https://picsum.photos/id/233/600/350",
  "https://picsum.photos/id/231/600/350",
  "https://picsum.photos/id/230/600/350",
];
var visibleSlide = ref(0);
var direction = ref("left");

setInterval(autoSlide, 5000);

function autoSlide() {
  if (visibleSlide.value >= slides.length - 1) {
    visibleSlide.value = 0;
  } else {
    visibleSlide.value++;
  }
  direction.value = "right";
}

function next() {
  if (visibleSlide.value >= slides.length - 1) {
    visibleSlide.value = 0;
  } else {
    visibleSlide.value++;
  }
  direction.value = "right";
}

function prev() {
  if (visibleSlide.value <= 0) {
    visibleSlide.value = slides.length - 1;
  } else {
    visibleSlide.value--;
  }
  direction.value = "left";
}
</script>

<style scoped>
.carousel-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.slide-image {
  border-radius: 20px;
}
</style>