<template>
  <div class="contact"><ContactComponent /></div>
</template>

<script>
import ContactComponent from "@/components/ContactComponent.vue";

export default {
  name: "ContactView",
  components: {
    ContactComponent,
  },
};
</script>
<style>
.contact {
  margin-top: 50px;
  text-align: center;
}
</style>