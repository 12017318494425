
<template>
  <div style="display: flex; flex-direction: column; height: 100%">
    <HeaderComponent style="position: sticky; top: 0px; z-index: 10; min-width: 500px" />

    <router-view />
  </div>
  <!-- <header class="header">
      <div class="ddMenu"><menu-item></menu-item><a href="">Menu</a></div>
      <div class="lgo"><router-link to="/">L.A.</router-link></div>
      <div class="contactUs">
        <button @click="openContactView()" class="contactButton">
          Contact Us
        </button>
      </div>
    </header> -->

  <!-- <header class="top-navigation">
      <div class="container">
        <div class="test-container">
          <div class="logo-container">
             <div class="logo">
              <router-link to="/">L.A.</router-link>
            </div> 
          </div>
          <div class="menu-center">
            <div class="logo">
              <router-link to="/">L.A.</router-link>
            </div>
             <div class="menu-item">
               <router-link to="/slider">Slider</router-link> |
              <router-link to="/contact">Contact</router-link> |
              <router-link to="/about">About</router-link> 
            </div> 
          </div>
          <div class="menu-right">
             <div class="menu-item">
              <label class="lang" @click="changeToFR">FR</label> |
              <label class="lang" @click="changeToDE">DE</label>
            </div> 
          </div>
        </div>
      </div>
    </header> -->
  <!-- <div class="image-container"><img src="App.vue" alt="erorr" /></div> -->
</template>

<script setup>
// import i18n from "@/i18n";
import HeaderComponent from "./components/HeaderComponent.vue";
import { useWindowStore } from "./store/window.store";
useWindowStore().init();

// function changeToDE() {
//   i18n.global.locale = "de";
//   this.$forceUpdate();
//   localStorage.setItem("savedLocale", "de");
//   console.log(i18n.global.locale);
// }
// function changeToFR() {
//   i18n.global.locale = "fr";
//   console.log(i18n.global.locale);
//   localStorage.setItem("savedLocale", "fr");
// }
</script>

<style>
html {}

body {
  margin: 0px;
  background: linear-gradient(335deg,
      #edf2fb 0%,
      #e2eafc 14%,
      #d7e3fc 28%,
      #ccdbfd 42%,
      #c1d3fe 56%,
      #b6ccfe 70%,
      #abc4ff 84%,
      #abc4ff 100%);
  background-attachment: fixed;
}

@font-face {
  font-family: "Kanit";
  src: url("@/assets/fonts/Kanit-Regular.ttf");
}

#app {
  font-family: "Kanit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.fade-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style> 
