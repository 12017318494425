<template>
  <div class="contact">
    <ApenzellComponent2 />
  </div>
</template>
    
<script>
import ApenzellComponent2 from "@/components/ApenzellComponent2.vue";

export default {
  name: "ApenzellView2",
  components: {
    ApenzellComponent2,
  },
};
</script>
<style>

</style>