import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from '../views/ContactView'
import SliderView from '../views/SliderView'
import LanguageView from '../views/LanguageView'
import ServicesView from '../views/ServicesView'
import PortfolioView from '../views/PortfolioView'
import PortfolioViewMobile from '../views/PortfolioViewMobile'
import ICalView from '../views/ICalView'
import ApenzellView from '../views/ApenzellView'
import ApenzellView2 from '../views/ApenzellView2'

import WolfVisionView from '../views/WolfVisionView'

const routes = [


  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView

  },
  {
    path: '/slider',
    name: 'slider',
    component: SliderView
  },
  {
    path: '/slider',
    name: 'language',
    component: LanguageView
  }, {
    path: '/services',
    name: 'services',
    component: ServicesView

  }, {
    path: '/portfolio',
    name: 'portfolio',
    component: PortfolioView
  }, {
    path: '/portfolioMobile',
    name: 'portfolioMobile',
    component: PortfolioViewMobile
  }
  , {
    path: '/ical',
    name: 'iCal',
    component: ICalView
  }
  , {
    path: '/apenzell',
    name: 'apenzell',
    component: ApenzellView
  },
  {
    path: '/apenzell2',
    name: 'apenzell2',
    component: ApenzellView2
  },

  {
    path: '/wolfvision',
    name: 'wolfvision',
    component: WolfVisionView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
