<template>
  <div class="contact"><ICalComponent /></div>
</template>
  
  <script>
import ICalComponent from "@/components/ICalComponent.vue";

export default {
  name: "ICaltView",
  components: {
    ICalComponent,
  },
};
</script>
  <style>
</style>