<template >
  <div>
    <div>
      <h1>Contact</h1>
    </div>
    <div class="image-container">
      <img src="@/assets/Download.jpeg" alt="test" class="image" />
    </div>
    <div class="container">
      <h3>Firma Lukas Aichbauer</h3>
      <a href="mailto:test.test@atestmail.tst">✉ test.test@atestmail.tst</a>
      <p class="Tel">☎ + ?? 1239232353</p>
    </div>
  </div>
</template>
<!-- <script>
export default {
  name: "ContactComponent",
};
</script> -->
<script setup>
import { onMounted, onUnmounted } from "vue";

onMounted(() => {
  console.log("Page was mounted!");
});

onUnmounted(() => {
  console.log("Page was unmounted!");
});
</script>

<style scoped>
.image {
  border: 2px solid black;
  border-radius: 100%;
}
/* .image-container {
  -webkit-box-shadow: 5px 5px 15px 0px #000000;
  box-shadow: 5px 5px 15px 0px #000000;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 20px;
  border: 2px solid black;
  border-radius: 100%;
} */
.Tel {
  margin-top: 20;
}
.container {
  margin-top: 70px;
}
</style>