<template>
  <div class="carousel">
    <slot> </slot>

    <button @click="next" class="next">Next</button>
    <button @click="prev" class="prev">Prev</button>
  </div>
</template>


<!-- <script>
export default {
  data() {
    return {};
  },
  methods: {
    next() {
      this.$emit("next");
    },
    prev() {
      this.$emit("prev");
    },
  },
};
-->
<script setup>
const emit = defineEmits(["next", "prev"]);
function next() {
  emit("next");
}
function prev() {
  emit("prev");
}
</script>

<style scoped>
.carousel {
  position: relative;
  width: 600px;
  height: 350px;
  overflow: hidden;
}
button {
  visibility: hidden;
  position: absolute;
  height: 40;
  width: 50;
  top: calc(50% - 20px);
  background-color: black;
  border: none;
  color: #fff;
}
button:focus button:hover {
  outline: none;
  cursor: pointer;
}
.next {
  right: 0;
}
.prev {
  left: 0;
}
</style>