<template>
  <div>
    <PortfolioMobile />
  </div>
</template>
      
      <script>
import PortfolioMobile from "@/components/PortfolioComponentMobile.vue";
export default {
  name: "PortfolioViewMobile",
  components: {
    PortfolioMobile,
  },
};
</script>