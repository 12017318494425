import router from "../router";

import AppenzellImage1 from "../assets/apenzell24/a24_1.png"

import ICalImage from "../assets/portfolio/ical.png";
import ApenzellImage from "../assets/portfolio/24.png";
import VueCMS from "../assets/portfolio/vuecms.png";
import UseWhatuHave from "../assets/portfolio/useWhatUHave.png";
import WolfVision from "../assets/portfolio/wolfVision.png";
import PortfolioItem from "@/model/portfolioItem";

export default function useData() {
  const data: Array<PortfolioItem> = [
    {
      id: 1,
      image: ICalImage,
      title: "iCal Import/Export CalDAV",
      text: "test",
      path: "/ical",
      route: () => router.push("/ical"),
      detail: {
        title: "iCal Import/Export CalDAV",
        images: [
          require("../assets/ical/ical1.png"),
          require("../assets/ical/ical2.png"),
          require("../assets/ical/ical3.png"),
          require("../assets/ical/ical4.png"),
          require("../assets/ical/ical5.png"),

        ],
        text: "iCal Import/Export CalDAV 3.2 ist das ultimative Import/Export/Sync App für Ihren Android Kalender mit erstklassigem Support für jeden. Sehen Sieimmer was passiert, nichts bleibt dem Benutzer verborgen.",
      },
    },
    {
      id: 2,
      image: ApenzellImage,
      title: "Appenzell24",
      text: "What a beautiful sunrise2",
      path: "/apenzell2",
      route: () => router.push("/apenzell2"),
      detail: {
        title: "Apenzell 24",
        images: [
          require("../assets/apenzell24/a24_1.png"),
          require("../assets/apenzell24/a24_2.png"),
          require("../assets/apenzell24/a24_3.png"),
          require("../assets/apenzell24/a24_4.png"),
        ],
        text: "Die „appenzell24.ch“-App bringt die aktuellsten lokalen Nachrichten aus dem Appenzellerland. Die App ist gratis und bietet verschiedenste Funktionen.",
      },
    },
    {
      id: 3,
      image: VueCMS,
      title: "VueCMS Proof of concept",
      text: "What a beautiful sunrise3",
      route: () => window.open("https://kirakoppandi.at", "_blank"),
      detail: {
        title: "Apenzell 24",
        images: [
          AppenzellImage1,
          "@/assets/apenzell24/a24_2.png",
          "@/assets/apenzell24/a24_3.png",
          "@/assets/apenzell24/a24_4.png",
        ],
        text: "Die „appenzell24.ch“-App bringt die aktuellsten lokalen Nachrichten aus dem Appenzellerland. Die App ist gratis und bietet verschiedenste Funktionen.",
      },
    },
    {
      id: 4,
      image: UseWhatuHave,
      title: "use what you have",
      text: "What a beautiful sunrise4",
      route: () => window.open("https://hard.usewhatyouhave.at", "_blank"),
      detail: {
        title: "Apenzell 24",
        images: [
          "../assets/apenzell24/a24_1.png",
          "../assets/apenzell24/a24_2.png",
          "../assets/apenzell24/a24_3.png",
          "../assets/apenzell24/a24_4.png",
        ],
        text: "Die „appenzell24.ch“-App bringt die aktuellsten lokalen Nachrichten aus dem Appenzellerland. Die App ist gratis und bietet verschiedenste Funktionen.",
      },
    },
    {
      id: 5,
      image: WolfVision,
      title: "vSolution App für WolfVision ",
      text: "What a beautiful sunrise5",
      path: "/wolfvision",
      route: () => router.push("/wolfvision"),
      detail: {
        title: "WolfVisiom",
        images: [
          require("../assets/vSolution/vs1.png"),
          require("../assets/vSolution/vs2.png"),
          require("../assets/vSolution/vs3.png"),
          require("../assets/vSolution/vs4.png"),
          require("../assets/vSolution/vs5.png"),
          require("../assets/vSolution/vs6.png"),
          require("../assets/vSolution/vs7.png"),
          require("../assets/vSolution/vs8.png"),
        ],
        text: "Die „appenzell24.ch“-App bringt die aktuellsten lokalen Nachrichten aus dem Appenzellerland. Die App ist gratis und bietet verschiedenste Funktionen.",
      },
    },
    // {
    //   id: 6,
    //   image:
    //     "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY",
    //   title: "test6",
    //   text: "What a beautiful sunrise6",
    //   route: "",
    // },
  ];
  return { data };
}