<template>
  <transition :name="direction" mode="in-out">
    <div v-if="visibleSlide === index" class="carousel-slide">
      <slot> </slot>
    </div>
  </transition>
</template>


<script>
export default {
  props: ["visibleSlide", "index", "direction"],
  data() {
    return {};
  },
};
</script>

<style>
.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;
}
/* .right-enter-from {
  animation: leftInAnimation 1s ease-in-out;
}

.right-leave-to {
  animation: leftOutAnimation 1s ease-in-out;
} */
/* 

@keyframes leftInAnimation {
  form {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }opacity: 0;
}
@keyframes leftOutAnimation {
  form {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
*/
.left-leave-active {
  animation: rightOutAnimation 0.5s ease-in-out;
}
.left-enter-active {
  animation: rightInAnimation 0.5s ease-in-out;
}
.right-leave-active {
  animation: leftOutAnimation 0.5s ease-in-out;
}
.right-enter-active {
  animation: leftInAnimation 0.5s ease-in-out;
}

@keyframes leftInAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes leftOutAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes rightInAnimation {
  form {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes rightOutAnimation {
  form {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>